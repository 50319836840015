import logo from './logo.svg';
import './App.css';
import { ReactComponent as DeviceAppSVG } from './images/device-app.svg';
import { ReactComponent as PleggitHandAndPayoffSVG } from './images/pleggit-hand-payoff.svg';
import { ReactComponent as DownloadAppleSVG } from './images/download-apple.svg';
import { ReactComponent as DownloadGoogleSVG } from './images/download-play.svg';
import { ReactComponent as CurvesSVG } from './images/curvy-background.svg';
import { ReactComponent as MotoItSVG } from './images/moto-it.svg';
import Footer from './Footer';

function App() {

  const playstorelink = () => {
      window.open("https://play.google.com/store/apps/details?id=com.pleggitmobile", "_blank");
  }

  const appstorelink = () => {
      window.open("https://apps.apple.com/us/app/pleggit/id1629201101", "_blank");
  }
  return (
    <div className="landing">
      <div className="moto-header">
        <MotoItSVG/>
      </div>
      <div className="section section-pub grad">
        <div className="left">
          <div className="partnership">
            <div className="pleggit-logo"><PleggitHandAndPayoffSVG /></div>
          </div>
          <div className="headline">
            Trovata l'occasione su <b>Moto.it</b>?<br />
            Paga con <b>Pleggit</b>.
          </div>
          <div className="payoff standard-text">
            Sicuro chi <b>vende</b>,<br />
            sicuro chi <b>compra</b>.
          </div>
        </div>
        <div className="center-image">
          <DeviceAppSVG />
        </div>
        <div className="right">
          <div className="download-text">
            <b>Scarica l'app</b>
          </div>
          <div className="download-buttons">
            <div onClick={appstorelink} id="downloadApple1"><DownloadAppleSVG /></div>
            <div onClick={playstorelink} id="downloadAndroid1"><DownloadGoogleSVG /></div>
          </div>
        </div>
        <div className="curvy-background">
          <CurvesSVG />
        </div>
      </div>

      <div className="section section-how">
        <div className="heading">
          Utilizza <b>Pleggit</b><br />
          per gestire il <b>pagamento</b> di un veicolo usato. <br />
          Scegli una procedura <b>semplice</b> e <b>sicura</b>!
        </div>
        <div className="carousel">
          <div className="step">
            <div className="image"><img src="images/MOTO_step0.png" /></div>
            <div className="text">Trova il tuo usato su <b>Moto.it</b></div>
          </div>
          <div className="step">
            <div className="image"><img src="images/PLEGGIT_step1.png" /></div>
            <div className="text"><b>Scarica l’app</b> e <b>falla scaricare</b> alla tua controparte.</div>
          </div>
          <div className="step">
            <div className="image"><img src="images/PLEGGIT_step2.png" /></div>
            <div className="text">Crea <b>connessione</b> con la tua controparte.</div>
          </div>
          <div className="step">
            <div className="image"><img src="images/PLEGGIT_step3.png" /></div>
            <div className="text">Segui la procedura e <b>invia i documenti</b> richiesti.</div>
          </div>
          <div className="step">
            <div className="image"><img src="images/PLEGGIT_step4.png" /></div>
            <div className="text">Attendi conferma e <b>concludi la compravendita</b>.</div>
          </div>
        </div>
      </div>

      <div className="section section-download">
        <div className="headline">
          Scarica subito l’app <b>Pleggit</b><br />
          e cogli al volo il tuo <b>affare migliore</b>!
        </div>
        <div className="stores">
            <div onClick={appstorelink} id="downloadApple2"><DownloadAppleSVG /></div>
            <div onClick={playstorelink} id="downloadAndroid2"><DownloadGoogleSVG /></div>
        </div>
      </div>
      
      <Footer/>
    </div>
  );
}

export default App;
